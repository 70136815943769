import React from 'react';
import '../../css/about.scss';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../../components/Layout';
import HeadingDecorated from '../../components/HeadingDecorated';
import TeamMember from '../../components/TeamMember';
import Seo from '../../components/Seo';

export const LOCALIZED_PAGES = {
  pl: '/o-nas/wladze-instytutu/',
  en: '/about-us/management/',
};

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    {
      zarzad: allStrapiTeamMember(
        sort: { fields: position, order: ASC }
        filter: { section: { eq: "zarzad" } }
      ) {
        nodes {
          bio {
            data {
              bio
            }
          }
          email
          fullName
          jobTitle
          avatar {
            localFile {
              url
            }
          }
        }
      }
      rada: allStrapiTeamMember(
        sort: { fields: position, order: ASC }
        filter: { section: { eq: "rada" } }
      ) {
        nodes {
          bio {
            data {
              bio
            }
          }
          fullName
          jobTitle
          avatar {
            localFile {
              url
            }
          }
        }
      }
    }
  `);

  return (
    <Layout localizedPages={LOCALIZED_PAGES}>
      <Seo title="O nas | Władze instytutu" />
      <div className="about">
        <section>
          <HeadingDecorated className="about__section-heading">
            Zarząd
          </HeadingDecorated>
          <div className="about__section-content">
            {data.zarzad.nodes.map((item) => (
              <TeamMember data={item} key={item.fullName} />
            ))}
          </div>
        </section>
        <section>
          <HeadingDecorated className="about__section-heading">
            Rada Fundacji
          </HeadingDecorated>
          <div className="about__section-content">
            {data.rada.nodes.map((item) => (
              <TeamMember data={item} key={item.fullName} />
            ))}
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default AboutPage;
